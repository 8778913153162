import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

const loader = () => {
  if (!detectMob()) {
    return import("@components/SignUp/NewSignUp")
  } else {
    return import("@components/SignUp/SignUpMobile")
  }
}

const SignUp = ReactDynamicImport({ loader })

const SignUpPage = ({ location }) => {
  return (
    <>
      <SEO title="Sign Up" />
      <SignUp location={location} />
    </>
  )
}

export default SignUpPage
